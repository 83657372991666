import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-midland-texas.png'
import image0 from "../../images/cities/scale-model-of-pumpjacks-mural-in-midland-texas.png"
import image1 from "../../images/cities/scale-model-of-sibley-nature-center-in-midland-texas.png"
import image2 from "../../images/cities/scale-model-of-blakemore-planetarium-at-the-museum-of-the-southwest-in-midland-texas.png"
import image3 from "../../images/cities/scale-model-of-george-bush-childhood-home-museum-in-midland-texas.png"
import image4 from "../../images/cities/scale-model-of-museum-of-the-southwest-in-midland-texas.png"
import image5 from "../../images/cities/scale-model-of-permian-basin-charity-golf-crs-in-midland-texas.png"
import image6 from "../../images/cities/scale-model-of-amera-travel-network-in-midland-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Midland'
            state='Texas'
            image={image}
            lat='31.9973456'
            lon='-102.07791459999999'
            attractions={ [{"name": "Pumpjacks Mural", "vicinity": "400-424 W Illinois Ave, Midland", "types": ["point_of_interest", "establishment"], "lat": 31.9995123, "lng": -102.07843479999997}, {"name": "Sibley Nature Center", "vicinity": "1307 E Wadley Ave, Midland", "types": ["park", "point_of_interest", "establishment"], "lat": 32.0331306, "lng": -102.07073079999998}, {"name": "Blakemore Planetarium at the Museum of the Southwest", "vicinity": "1705 W Missouri Ave, Midland", "types": ["point_of_interest", "establishment"], "lat": 31.9923767, "lng": -102.09293070000001}, {"name": "George Bush Childhood Home Museum", "vicinity": "1412 W Ohio Ave, Midland", "types": ["museum", "point_of_interest", "establishment"], "lat": 31.9987025, "lng": -102.0913028}, {"name": "Museum of the Southwest", "vicinity": "1705 W Missouri Ave, Midland", "types": ["museum", "point_of_interest", "establishment"], "lat": 31.993304, "lng": -102.09251899999998}, {"name": "Permian Basin Charity Golf Crs", "vicinity": "408 W Wall St, Midland", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 31.9975489, "lng": -102.07858099999999}, {"name": "Amera Travel Network", "vicinity": "4610 N Garfield St Suite A3, Midland", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.0395159, "lng": -102.11180200000001}] }
            attractionImages={ {"Pumpjacks Mural":image0,"Sibley Nature Center":image1,"Blakemore Planetarium at the Museum of the Southwest":image2,"George Bush Childhood Home Museum":image3,"Museum of the Southwest":image4,"Permian Basin Charity Golf Crs":image5,"Amera Travel Network":image6,} }
       />);
  }
}